import React, { useState } from 'react';

const data = [
  {
    id: 1,
    question: 'Is it an aggregator?',
    answer:
      'No, all courses are reviewed manually. Course creators are contacted personally if needed to clarify additional information.',
  },
  {
    id: 2,
    question: 'Are courses yours?',
    answer: 'No, courses are found on the internet.',
  },
  {
    id: 3,
    question: 'Who are the experts?',
    answer:
      'I have over 10 years of experience with learning programming online and I review courses based on the knowledge that I wish I had when I started.',
  },
  {
    id: 4,
    question: 'How are courses found?',
    answer:
      'I search the internet for courses that address specific and real problems, and I often come across course creators who teach useful stuff. Sadly, not many people know about them.',
  },
  {
    id: 5,
    question: 'What are the criteria for choosing courses?',
    answer:
      'The learning outcome of a course should be applicable in real-world projects, or otherwise, it is useless to spend time on building yet another basic TODO app.',
  },
  {
    id: 6,
    question: 'Are all courses for beginners only?',
    answer:
      "No, it's for everyone interested in programming. For beginners and advanced developers alike.",
  },
];

const Item = ({ data }) => {
  const [showElement, setShowElement] = useState(false);

  return (
    <>
      <li className="pb-3" key={data.id.toString()} onClick={() => setShowElement(!showElement)}>
        <span className="font-bold cursor-pointer">{data.question}</span>
        <br />

        {showElement && <span className="block text-base">{data.answer}</span>}
      </li>
    </>
  );
};

const Faq = () => {
  return (
    <>
      <div className="text-xl">FAQ</div>
      <p className="mb-5 text-lg text-medium-on-surface-0">
        <ul>
          {data.map(item => (
            <Item data={item} />
          ))}
        </ul>
      </p>
    </>
  );
};

export default Faq;
