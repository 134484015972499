import React from 'react';
import Subscribe from './Subscribe';
import Faq from './Faq';
import SocialShareButtons from './SocialShareButtons';
import { CodingIcon } from '../img/icons';

const MainPageContent = class extends React.Component {
  render() {
    return (
      <section className="md:max-w-3xl lg:max-w-4xl p-6 md:p-4 md:pl-12 md:pt-8 md:text-left">
        <h1 className="text-3xl md:text-4xl font-bold text-primary leading-tight mb-2">
          Online programming courses that are worth taking
        </h1>
        <p className="md:max-w-1xl lg:max-w-2xl mb-10 text-xl md:text-2xl text-secondary font-normal">
          All courses are hand-picked and carefully reviewed by experts to give you the best
          learning experience
        </p>

        <div className="md:max-w-xl">
          <div className="text-xl">Motivation</div>

          <p className="mb-5 text-lg text-medium-on-surface-0">
            Most courses oversimplify things giving you a false idea of how simple programming is.
            CompareCourses fixes it by manually filtering all courses from the internet that teach
            real-life programming.
          </p>
          <div className="text-xl">How do I know</div>
          <p className="mb-5 text-lg text-medium-on-surface-0">
            I am a software engineer, and my first online learning experience was with Lynda.com’s
            JavaScript courses over 10 years ago. <br />
            Since then, I've been learning software development online.
          </p>
          <div className="text-xl">Features</div>
          <p className="mb-5 text-lg text-medium-on-surface-0">
            For beginners, courses will be combined into learning paths with as little overlap in
            tech and topics as possible so that one can learn more with fewer courses and in less
            time.
          </p>
          <p className="mb-5 text-lg text-medium-on-surface-0">
            For advanced devs, there will be courses that focus on solving real-life problems using
            alternative tech stack and advanced techniques that experienced engineers may want to
            master.
          </p>
          <Faq />
          <div className="mb-5">
            <Subscribe />
          </div>
          <div className="mb-5">
            <SocialShareButtons />
          </div>
          <div className="flex justify-center mb-5">
            <CodingIcon className="h-20 opacity-50" />
          </div>
        </div>
      </section>
    );
  }
};

export default MainPageContent;
